@font-face {
  font-family: "BebasNeue";
  src: url("assets/fonts/Bebas_Neue/BebasNeue-Regular.ttf") format("TrueType")
}

@font-face {
  font-family: "Oswald";
  src: url("assets/fonts/Oswald/Oswald-VariableFont_wght.ttf") format("TrueType")
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  color: white;
  font-size: 62px;
  font-weight: 900;
  line-height: 1.1;
  margin: 0;
  padding: 0;
  font-family: BebasNeue;
}

h2 {
  color: white;
  font-size: 40px;
  font-weight: 900;
  line-height: 1.1;
  margin: 0;
  padding: 0;
  font-family: BebasNeue;
}

h3 {
  color: white;
  font-size: 15px;
  font-weight: 900;
  line-height: 1.1;
  margin: 5;
  padding: 0;
  font-family: Oswald;
}

h4 {
  color: white;
  font-size: 30px;
  line-height: 1.1;
  margin: 0;
  padding: 0;
  font-family: Oswald;
}

p {
  color: white;
  font-size: 20px;
  font-family: Oswald;
}